export const SHOW_EVENT = 'show.appotential'
export const HIDE_EVENT = 'hide.appotential'
export const APP_CONFIG = 'appotential.app'

export const DEFAULT_ALL = 'ALL'

export const POOLS = ['10', '20', '30', '40', '50', '60', '70', '80', '90']
export const DEFAULT_POOL = '60'

export const SCORE_TIERS_TOT = 'TOT'
export const SCORE_TIERS_EBRW = 'EBRW'
export const SCORE_TIERS_R = 'R'
export const SCORE_TIERS_W = 'W'
export const SCORE_TIERS_M = 'MSS'

export const ACCESS_CODE_LINK = `https://cbaccount.collegeboard.org/professional/enroll?prgCode=APP&appId=110`

// AP-45284: standard error message for all errors.
export const ERROR = {
  DEFAULT: {
    title: `Error`,
    message: `Error loading data. Please try again at a later time.`,
  },
  ADMIN_SAVE_ASSESSMENTS: {
    message: `Error: Unable to save assessments.`,
  },
  ADMIN_LOAD_SETTINGS: {
    title: 'Error',
    message: `Unable to Load Settings.`,
  },
  ADMIN_SAVE_SETTINGS: {
    message: `Error: Unable to save settings`,
  },
  AUTH: {
    title: 'AP Potential Could Not Load',
    message: 'An error occurred while initializing AP Potential. Please refresh the page to try again.',
  },
  ACCESS_DENIED: {
    title: 'Your account does not have access to AP Potential',
    message: (
      <div>
        <p>
          Contact your AP coordinator or principal for an access code. You may also call AP Services at (877) 274-6474
          (toll free in the United States and Canada) or (212) 632-1781.
        </p>
        <br />
        <a href={ACCESS_CODE_LINK}>
          <b> Add your access code here.</b>
        </a>
      </div>
    ),
  },
}
