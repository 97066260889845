import get from 'lodash/get'

export const iam = () => get(window, 'cb.core.iam')
export const init = () => iam().init()
export const getCatapultId = () => iam().getTempAWSCredsSubject()
export const getCBEnv = () => iam().getCBEnv()
export const getAuthSession = () => iam().getAuthSession() || {}
export const getAuthorizationToken = () => iam().getAuthorizationToken()
export const getAuthenticationToken = () => iam().getAuthenticationToken()
export const hasLoginCookie = () => document.cookie.includes('cb_login')

export const ROLE_DISTRICT = '21'
export const ROLE_SCHOOL = '22'
export const ROLE_K12DETAILEDREPORTS = '611' // k12 portal user type, treated same as school (22)
export const ROLE_STATE = '23'
export const ROLE_REGION = '101'
export const ROLE_INTERNAL = '41'
export const NON_K12_ROLES = [ROLE_DISTRICT, ROLE_SCHOOL, ROLE_STATE, ROLE_REGION, ROLE_INTERNAL]
export const ROLES = [...NON_K12_ROLES, ROLE_K12DETAILEDREPORTS]

// ASSUMPTIONS
// users should only be associated with single org, except for schools
// users should only be associated with a single role type
export const getRoles = (roles) => {
  const token = getAuthorizationToken()
  const { cb } = token ? JSON.parse(window.atob(token.split('.')[1])) : {}

  // if the user has an APP role/org, omit their K12 detailed reports role/org
  if (cb?.ro?.filter((r) => NON_K12_ROLES.includes(r.rid)).length > 0) {
    return (
      cb?.ro?.filter((r) => NON_K12_ROLES.includes(r.rid) && (!Array.isArray(roles) || roles.includes(r.rid))) || []
    )
  }

  return cb?.ro?.filter((r) => ROLES.includes(r.rid) && (!Array.isArray(roles) || roles.includes(r.rid))) || []
}
export const isDistrict = () => getRoles([ROLE_DISTRICT]).length
export const isSchool = () => getRoles([ROLE_SCHOOL, ROLE_K12DETAILEDREPORTS]).length
export const isState = () => getRoles([ROLE_STATE]).length
export const isInternal = () => getRoles([ROLE_INTERNAL]).length
export const isRegion = () => getRoles([ROLE_REGION]).length
export const isMultiOrgUser = () => getRoles().length > 1
export const getAllowedOrgs = (roles) => getRoles(roles).map((r) => r.oid)
export const getAllowedSchools = () => getAllowedOrgs([ROLE_SCHOOL, ROLE_K12DETAILEDREPORTS])
export const getAllowedDistricts = () => getAllowedOrgs([ROLE_DISTRICT])

export const checkOrgAccess = (stateOrgId, regionOrgId, districtOrgId, orgId) =>
  isInternal() ||
  getRoles().find(({ oid }) => oid === stateOrgId || oid === regionOrgId || oid === districtOrgId || oid === orgId)

// app permissions
export const canViewApp = () => isDistrict() || isSchool() || isState() || isInternal() || isRegion()
export const canSelectInstitution = () => isDistrict() || isState() || isInternal() || isRegion()
export const canSelectState = () => isState() || isInternal() || isRegion()
export const canDownload = () => isState() || isDistrict() || isSchool() || isRegion()
export const canViewDistrictData = () => isState() || isDistrict() || isRegion() || isInternal()

export const DEFAULT_AUTH = {
  loading: false,
  loggedIn: false,
  loggedOut: true,
  error: false,
}

export const useAuth = () => {
  const { getEventBus, events } = iam()
  const bus = getEventBus()
  const { Logout, AWSLogin, AWSLoginFailed } = events
  const hasLoginCookie = document.cookie.includes('cb_login')
  const hasAWSCreds = Boolean(getCatapultId())
  const [auth, setAuth] = useState({
    loading: hasLoginCookie && !hasAWSCreds,
    loggedIn: hasAWSCreds,
    loggedOut: !hasLoginCookie && !hasAWSCreds,
    error: false,
  })

  bus.on(Logout, () => setAuth(DEFAULT_AUTH))
  bus.on(AWSLogin, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, loggedOut: false }))
  bus.on(AWSLoginFailed, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, error: true, loggedOut: false }))

  return auth
}
